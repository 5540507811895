import React from 'react';
import ReactDOM from 'react-dom';
import { attachTo } from 'common/components/legacy';
import PendoOptIn from './components/PendoOptIn';

attachTo(document.querySelector('.site-appearance'));

const pendoTarget = document.querySelector('#pendo-opt-in');

if (pendoTarget) {
  ReactDOM.render(
    <PendoOptIn
      targetUrl={pendoTarget.getAttribute('data-target-path')}
      pendoDisabled={pendoTarget.getAttribute('data-pendo-allowed') === 'false'}
      pendoStatus={pendoTarget.getAttribute('data-pendo-status')}
    />,
    pendoTarget
  );
}
