import React, { Component } from 'react';
import Button, { VARIANTS } from 'common/components/Button';
import Modal, { ModalContent, ModalFooter, ModalHeader } from 'common/components/Modal';
import Radiobutton from 'common/components/Radiobutton';
import { fetchJsonWithParsedError as fetch } from 'common/http';
import I18n from 'common/i18n';

// There is an equivalent list of Pendo status values in SiteAppearance::PENDO_STATUSES
const PENDO_STATUSES = {
  OPTED_IN: 'opted_in',
  OPTED_IN_ANONYMOUS: 'opted_in_anonymous',
  OPTED_OUT: 'opted_out'
};

const STRINGS = {
  defaultText: I18n.t('screens.admin.site_appearance.tabs.general.fields.pendo.modal_description'),
  fedrampText: I18n.t('screens.admin.site_appearance.tabs.general.fields.pendo.modal_text_fedramp'),
  modalTitle: I18n.t('screens.admin.site_appearance.tabs.general.fields.pendo.modal_title'),
  optedInText: I18n.t('screens.admin.site_appearance.tabs.general.fields.pendo.opted_in_html'),
  optedInAnonymousText: I18n.t(
    'screens.admin.site_appearance.tabs.general.fields.pendo.opted_in_anonymous_html'
  ),
  optedOutText: I18n.t('screens.admin.site_appearance.tabs.general.fields.pendo.opted_out_html'),
  saveText: I18n.t('screens.admin.site_appearance.tabs.general.fields.pendo.save'),
  settingsText: I18n.t('screens.admin.site_appearance.tabs.general.fields.pendo.settings')
};

class PendoOptIn extends Component {
  state = {
    modalIsOpen: false,
    pendoStatus: this.props.pendoDisabled ? PENDO_STATUSES.OPTED_OUT : this.props.pendoStatus
  };

  handleModalDismiss = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ modalIsOpen: false });
  };

  handleCheckToggleOptedIn = () => {
    this.setState({ pendoStatus: PENDO_STATUSES.OPTED_IN });
  };

  handleCheckToggleOptedInAnonymous = () => {
    this.setState({ pendoStatus: PENDO_STATUSES.OPTED_IN_ANONYMOUS });
  };

  handleCheckToggleOptedOut = () => {
    this.setState({ pendoStatus: PENDO_STATUSES.OPTED_OUT });
  };

  handleOnSubmit = (event) => {
    const { targetUrl } = this.props;
    const body = {
      pendoStatus: this.state.pendoStatus
    };
    event.preventDefault();
    fetch(targetUrl, { method: 'PUT', body: JSON.stringify(body) });
    this.handleModalDismiss();
  };

  render() {
    const { pendoDisabled } = this.props;
    const buttonClass = pendoDisabled ? VARIANTS.DEFAULT : VARIANTS.PRIMARY;
    const descriptionText = pendoDisabled ? STRINGS.fedrampText : STRINGS.defaultText;

    return (
      <div>
        {this.state.modalIsOpen && (
          <Modal onDismiss={this.handleModalDismiss}>
            <ModalHeader title={STRINGS.modalTitle} onDismiss={this.handleModalDismiss} />
            <ModalContent>
              {descriptionText}
              <Radiobutton
                onChange={this.handleCheckToggleOptedIn}
                id="optedIn"
                checked={this.state.pendoStatus === PENDO_STATUSES.OPTED_IN}
                disabled={pendoDisabled}
                name="pendoStatus"
                label={<span dangerouslySetInnerHTML={{ __html: STRINGS.optedInText }} />}
              >
              </Radiobutton>
              <Radiobutton
                onChange={this.handleCheckToggleOptedInAnonymous}
                id="optedInAnonymous"
                checked={this.state.pendoStatus === PENDO_STATUSES.OPTED_IN_ANONYMOUS}
                disabled={pendoDisabled}
                name="pendoStatus"
                label={<span dangerouslySetInnerHTML={{ __html: STRINGS.optedInAnonymousText }} />}
              >
              </Radiobutton>
              <Radiobutton
                onChange={this.handleCheckToggleOptedOut}
                id="optedOut"
                checked={this.state.pendoStatus === PENDO_STATUSES.OPTED_OUT}
                disabled={pendoDisabled}
                name="pendoStatus"
                label={<span dangerouslySetInnerHTML={{ __html: STRINGS.optedOutText }} />}
              >
              </Radiobutton>
            </ModalContent>
            <ModalFooter>
              <Button
                className="save-pendo-settings"
                type="submit"
                onClick={this.handleOnSubmit}
                disabled={pendoDisabled}
                variant={buttonClass}
              >
                {STRINGS.saveText}
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <Button className="open-pendo-modal" onClick={() => this.setState({ modalIsOpen: true })}>
          {STRINGS.settingsText}
        </Button>
      </div>
    );
  }
}

export default PendoOptIn;
